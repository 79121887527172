import { Epic } from 'redux-observable'
import { mergeMap, map } from 'rxjs/operators';
import { profileAccountApi, tenantsApi, entitiesApi, relationshipsApi, RelationshipModel, ICompanySummaryModel, ICompanyModel, IGetMyEntitiesModel, IGetMyRelationshipsModel } from "proxy/apiProxy";
import { mapToPayload, onlyNotNull } from "lib/rxJsUtility";
import { referencesApi, parametersApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "..";
import { IThemingState } from "./slice";
import { base64toBlob, toDictionary } from 'lib/utility';
import { getTenantId, setTenantId } from 'lib/dataAccess';
import { from, merge, of } from 'rxjs';
import { getEntityName } from 'lib/modelUtils';
import { getUserManager } from 'lib/userManager';

export const applicationLoad: Epic<IAnyAction> = action$ =>
    action$.pipe(
        mapToPayload("app", "onSigninCallback"),
        mergeMap(async user => {
            const userManager = getUserManager();
            await userManager.storeUser(user);

            const accessibleTenants = await tenantsApi.getAccessibleTenantsAsync().catch(err => undefined);
            if (!accessibleTenants) {
                return [];
            }
            if (accessibleTenants.type === "NoTenantModel") {
                return null;
            }
            const tenants = accessibleTenants.tenants;
            let tenantId = getTenantId();
            if (!!tenantId) {
                const tenant = tenants.find(i => i.id === tenantId);
                if (!tenant) {
                    setTenantId(undefined);
                    tenantId = undefined;
                }
            }
            if (!tenantId) {
                if (tenants.length) {
                    tenantId = tenants[0].id;
                    setTenantId(tenantId);
                }
                else {
                    return [null, null, null, null, null, null, null, null, null, null];
                }
            }

            return await Promise.all([
                referencesApi.getAllReportTemplateCategoriesAsync().catch(err => undefined),
                profileAccountApi.getCurrentAsync().catch(err => undefined),
                parametersApi.getAsync().catch(err => undefined),
                profileAccountApi.getCurrentTenantAsync().catch(err => undefined),
                referencesApi.getAllCurrenciesAsync().catch(err => undefined),
                referencesApi.getAllReportTemplatesAsync().catch(err => undefined),
                referencesApi.getAllMonitoringMacroScriptsAsync().catch(err => undefined),
                referencesApi.getAllCountriesAsync().catch(err => undefined),
                referencesApi.getAllProcessClassificationTypesAsync().catch(err => undefined),
                Promise.resolve(tenants),
            ]);
        }),
        mergeMap(payload => {
            if (payload) {
                const [reportTemplateCategories, profile, parameters, currentTenant, currencies, reportTemplates, monitoringMacroScripts, countries, processClassificationTypes, accessibleTenants] = payload;
                if (profile) {
                    if (reportTemplateCategories && parameters && currentTenant && currencies && reportTemplates && monitoringMacroScripts && countries && processClassificationTypes && accessibleTenants) {
                        const applicationLoaded = ActionFactories.app.applicationLoaded({
                            reportTemplateCategories, profile, parameters, currentTenant, currencies, reportTemplates, monitoringMacroScripts, countries, processClassificationTypes, accessibleTenants
                        })
                        return from([applicationLoaded]);
                    }
                    else {
                        return from([ActionFactories.app.dummy()]);
                    }
                }
                else {
                    return from([ActionFactories.app.applicationLoadedWithNoGrants()]);
                }
            } else {
                return from([ActionFactories.app.dummy()]);
            }



            // return ActionFactories.app.applicationLoaded({
            //     reportTemplateCategories: await referencesApi.getAllReportTemplateCategoriesAsync(),
            //     profile: await profileAccountApi.getCurrentAsync(),
            //     parameters: await parametersApi.getAsync(),
            //     currentTenant: await profileAccountApi.getCurrentTenantAsync(),
            //     currencies: await referencesApi.getAllCurrenciesAsync(),
            //     reportTemplates: await referencesApi.getAllReportTemplatesAsync(),
            //     monitoringMacroScripts: await referencesApi.getAllMonitoringMacroScriptsAsync(),
            //     countries: await referencesApi.getAllCountriesAsync(),
            //     processClassificationTypes: await referencesApi.getAllProcessClassificationTypesAsync(),
            //     accessibleTenants: tenants
            // });
        })
    )
export const onSwitchTenant: Epic<IAnyAction> = action$ =>
    action$.pipe(mapToPayload("app", "switchTenant")).pipe(
        mergeMap(async tenantId => {
            const accessibleTenants = await tenantsApi.getAccessibleTenantsAsync();
            if (accessibleTenants.type === "NoTenantModel") {
                return null;
            }
            const tenants = accessibleTenants.tenants;
            if (!!tenantId) {
                const tenant = tenants.find(i => i.id === tenantId);
                if (!tenant) {
                    setTenantId(undefined);
                    return null;
                }
            }
            if (!tenantId) {
                setTenantId(undefined);
                return null;
            }

            setTenantId(tenantId);
            return tenants;
        }),
        onlyNotNull(),
        mergeMap(async tenants => ActionFactories.app.applicationLoaded({
            reportTemplateCategories: await referencesApi.getAllReportTemplateCategoriesAsync(),
            profile: await profileAccountApi.getCurrentAsync(),
            parameters: await parametersApi.getAsync(),
            currentTenant: await profileAccountApi.getCurrentTenantAsync(),
            currencies: await referencesApi.getAllCurrenciesAsync(),
            reportTemplates: await referencesApi.getAllReportTemplatesAsync(),
            monitoringMacroScripts: await referencesApi.getAllMonitoringMacroScriptsAsync(),
            countries: await referencesApi.getAllCountriesAsync(),
            processClassificationTypes: await referencesApi.getAllProcessClassificationTypesAsync(),
            accessibleTenants: tenants
        })));

export const applicationLoaded: Epic<IAnyAction> = action$ => action$.pipe(
    mapToPayload("app", "applicationLoaded"),
    mergeMap(async ({ accessibleTenants, currentTenant, profile }) => {
        return {
            accessibleTenants,
            currentTenant,
            entities:
                // { all: [], entities: {}, myId: profile.id } as IGetMyEntitiesModel, 
                await entitiesApi.getAllAsync(),
            relationships:
                await relationshipsApi.getAllAsync()
            // { all: [], entities: {}, portfolios: {}, relationships: {}, securities: {}, underlyingEntities: [] } as IGetMyRelationshipsModel
        };
    }),
    mergeMap(({ accessibleTenants, currentTenant, entities, relationships }) => merge(
        of(ActionFactories.contextPage.detailLoadedAll({ entity: entities, relationship: relationships })),
        of(null).pipe(
            mergeMap(async (i) => createThemesAsync(accessibleTenants, currentTenant, entities, relationships))),
        from(accessibleTenants).pipe(
            mergeMap(({ id }) => tenantsApi.getImageAsync({ id })
                .then(({ data, mimeType }) => ({ tenantId: id, imageUrl: window.URL.createObjectURL(base64toBlob(data, mimeType)) }))
                .catch(() => ({ tenantId: id }))),
            map(ActionFactories.app.tenantImageLoaded))
    )));

async function createThemesAsync(accessibleTenants: ICompanySummaryModel[], currentTenant: ICompanyModel, entities: IGetMyEntitiesModel, relationships: IGetMyRelationshipsModel) {
    const entityDictionary = toDictionary(await Promise.all([...entities.all, ...relationships.underlyingEntities, currentTenant]), i => i.id);
    const entityImages = toDictionary(await Promise.all(Object
        .values(entityDictionary)
        .map(({ id: entityId }) =>
            (currentTenant.id === entityId
                ? tenantsApi.getImageAsync({ id: entityId })
                : entitiesApi.getImageAsync({ entityId }))
                .then(({ data, mimeType }) => ({ entityId, imageUrl: window.URL.createObjectURL(base64toBlob(data, mimeType)) }))
                .catch(() => ({ entityId, imageUrl: undefined })))), i => i.entityId, i => i.imageUrl);
    const tenantTheme = {
        name: getEntityName(currentTenant),
        themeConfig: currentTenant.themeConfig,
        image: entityImages[currentTenant.id]
    } as IThemingState;

    const relationshipThemes = relationships.all.reduce((a, relationship) => {
        let typeGroup = a[relationship.type];
        if (!typeGroup) {
            typeGroup = {};
            a[relationship.type] = typeGroup;
        }

        const entity = entityDictionary[relationship.entityId];
        if (entity.hasPersonalPortalTheme) {
            typeGroup[relationship.id] = {
                name: getEntityName(entity),
                themeConfig: entity.themeConfig,
                image: entityImages[entity.id]
            } as IThemingState;
        }
        else {
            typeGroup[relationship.id] = tenantTheme;
        }

        return a;
    }, {} as Record<RelationshipModel["type"], Record<number, IThemingState>>);
    return ActionFactories.app.themesLoaded({
        Entity: toDictionary(entities.all, i => i.id, i => {
            if (i.hasPersonalPortalTheme) {
                return {
                    name: getEntityName(i),
                    themeConfig: i.themeConfig,
                    image: entityImages[i.id]
                } as IThemingState;
            }
            return tenantTheme;
        }),
        Counterparty: relationshipThemes?.CounterpartyRelationshipModel ?? {},
        Investor: relationshipThemes?.InvestorRelationshipModel ?? {},
        Role: relationshipThemes?.RoleRelationshipModel ?? {}
    });
}
