import 'typeface-roboto'
import ApplicationBar from "./ApplicationBar";
import { Grid, makeStyles } from '@material-ui/core';
import { useReduxActions } from 'lib/reduxStoreAccess';
import { HotKeys, KeyMap } from 'react-hotkeys';
import { useMemo } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Page from 'features/ContextPage/Page';

function convertMinHeightIntoMarginTop(input: any): any {
    return replaceNode(input);
    function replaceNode(i: any): any {
        if (!i || typeof i !== "object") {
            return i;
        }
        const output: Record<string, any> = {};
        for (const key in i) {
            if (i.hasOwnProperty(key)) {
                const element = i[key];
                const propKey = key === "minHeight" ? "marginTop" : key;
                output[propKey] = replaceNode(element);
            }
        }
        return output;
    }
}


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
    },
    content: {
        flexGrow: 1,
        display: "flex",
        padding: theme.spacing(3),
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        width: "100%",
        paddingTop: 64,
        ...convertMinHeightIntoMarginTop(theme.mixins.toolbar)
    },
    gridContainer: {
        flexGrow: 1,
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
}));

const router = createBrowserRouter([{
    path: "/",
    element: <AppPage />,
    children: [{
        index: true,
        element: <Page />,
    }, {
        path: "page/:type/:id",
        element: <Page />
    }],
}]);
const keyMap: KeyMap = {
    APPLICATION_SEARCH: "ctrl+shift+f"
}

function AppPage() {
    const classes = useStyles();
    const { searchShortcut } = useReduxActions("app");
    const shortcutHandlers = useMemo(() => ({ APPLICATION_SEARCH: (_?: KeyboardEvent) => searchShortcut() }), [searchShortcut]);
    return <HotKeys keyMap={keyMap} handlers={shortcutHandlers}  className={classes.root}>
        <ApplicationBar />
        <main className={classes.content}>
            <Grid container={true} justifyContent="center" alignItems="stretch" className={classes.gridContainer} spacing={3}>
                <Outlet />
            </Grid>
        </main>
    </HotKeys>
}

export function LoadedApplication() {
    return <RouterProvider router={router} />
}
